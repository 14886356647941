import mock from '../../mock'
import { paginateArray } from '../../utils'
 
const data = {
  labels: [
    { item: "ClientLogo", order: "1", content: require('@src/assets/images/avatars/10.png').default},
    { item: "Asset QR", order: "2", content: "<automatic>"},
    { item: "Choose QA Fields", order: "3", content: "EFG Hospital"},
    { item: "Asset Serial Number", order: "4", content: "<automatic>"},
    { item: "Client Note", order: "5", content: "abc"},
    { item: "LSM Note", order: "6", content: "efg"}
  ],
  pmlabels: [
    { item: "ClientLogo", order: "1", content: require('@src/assets/images/avatars/11.png').default},
    { item: "Asset QR", order: "2", content: "<automatic>"},
    { item: "Choose PM Fields", order: "3", content: "EFG Hospital"},
    { item: "Asset Serial Number", order: "4", content: "<automatic>"},
    { item: "Client Note", order: "5", content: "xyz"},
    { item: "LSM Note", order: "6", content: "wuv"}
  ],
  calibrationlabels: [
    { item: "ClientLogo", order: "1", content: require('@src/assets/images/avatars/12.png').default},
    { item: "Asset QR", order: "2", content: "<automatic>"},
    { item: "Choose Calibration Fields", order: "3", content: "EFG Hospital"},
    { item: "Asset Serial Number", order: "4", content: "<automatic>"},
    { item: "Client Note", order: "5", content: "def"},
    { item: "LSM Note", order: "6", content: "klm"}
  ],
  qrlabels: [
    { item: "ClientLogo", order: "1", content: require('@src/assets/images/avatars/6.png').default},
    { item: "Calibration/PM/QA Note", order: "2", content: "EFG Hospital"},
    { item: "Asset QR", order: "3", content: "<automatic>"},
    { item: "Asset Serial Number", order: "4", content: "<automatic>"},
    { item: "Client Note", order: "5", content: "def"},
    { item: "LSM Note", order: "6", content: "klm"}
  ]

}
// GET ALL DATA
mock.onGet('/api/masters/labels/label/all-qr-data').reply(() => {
  return [200, data.qrlabels]
})
mock.onGet('/api/masters/labels/label/all-data').reply(() => {
  return [200, data.labels]
})
mock.onGet('/api/masters/labels/label/all-pm-data').reply(() => {
  return [200, data.pmlabels]
})
mock.onGet('/api/masters/labels/label/all-calibration-data').reply(() => {
  return [200, data.calibrationlabels]
})

// GET USER
mock.onGet('/api/masters/labels/label').reply(config => {
  const { item } = config
  const label = data.labels.find(i => i.item === item)
  return [200, { label }]
})
