// ** Initial State
const initialState = {
  allData: [],
  data: [],
  allRoomData: [],
  total: 1,
  params: {},
  selectedClientZone: null
}

const ClientZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CLIENT_ZONE':
      return { ...state, allData: action.data }

    case 'GET_ALL_IN_ROOM_CLIENT_ZONE':
      return { ...state, allRoomData: action.data }
    case 'GET_ALL_IN_ASSET_CLIENT_ZONE':
      return { ...state, allData: action.data }
      
    case 'GET_DATA_CLIENT_ZONE':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLIENT_ZONE':
      return { ...state, selectedClientZone: action.selectedClientZone }
    case 'GET_CLIENT_ZONE_DOCUMENT':
      return { ...state, selectedClientZoneDocument: action.selectedClientZoneDocument }
    case 'GET_CLIENT_ZONE_IMAGE':
      return { ...state, selectedClientZoneImage: action.selectedClientZoneImage }
    case "CLEAR_ALL":
      return initialState
    
    case 'CLIENTZONEERROR':
      return { ...state, clientZoneError: action.data }
    default:
      return { ...state }
  }
}
export default ClientZoneReducer