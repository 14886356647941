// ** Initial State
const initialState = {
  branchData: [],
  serviceTicketBranchData: [],
  serviceTicketBranchCategoryData: [],
  serviceTicketBranchDepartmentData: [],
  serviceTicketBranchTechData: [],
  serviceTicketBranchWeekData: [],
  departmentLocationData: [],
  familyMakeModelData: []
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_BRANCH_CATEGORY':
      return { ...state, branchData: action.data }
    case 'GET_DATA_FAMILY_MAKE_MODEL':
      return { ...state, familyMakeModelData: action.data }
    case 'GET_DATA_DEPARTMENT_LOCATION':
      return { ...state, departmentLocationData: action.data }
    case 'GET_DATA_ST_BRANCH_CATEGORY':
      return { ...state, serviceTicketBranchCategoryData: action.data }
    case 'GET_DATA_ST_BRANCH':
      return { ...state, serviceTicketBranchData: action.data }
    case 'GET_DATA_ST_BRANCH_DEPARTMENT':
      return { ...state, serviceTicketBranchDepartmentData: action.data }
    case 'GET_DATA_ST_BRANCH_CATEGORY_TECH':
      return { ...state, serviceTicketBranchTechData: action.data }
    case 'GET_DATA_ST_BRANCH_CATEGORY_WEEK':
      return { ...state, serviceTicketBranchWeekData: action.data }
    
      case 'DASHBOARDERROR':
      return { ...state, dashboardError: action.data }
    default:
      return { ...state }
  }
}
export default DashboardReducer