// ** Initial State
const initialState = {
  allData: [],
  allRoomData: [],
  data: [],
  total: 1,
  params: {},
  selectedClientBuilding: null
}

const ClientBuildingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CLIENT_BUILDING':
      return { ...state, allData: action.data }
    case 'GET_ALL_IN_ROOM_CLIENT_BUILDING':
      return { ...state, allRoomData: action.data }
      case 'GET_ALL_IN_ASSET_CLIENT_BUILDING':
      return { ...state, allData: action.data }
      
    case 'GET_DATA_CLIENT_BUILDING':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLIENT_BUILDING':
      return { ...state, selectedClientBuilding: action.selectedClientBuilding }
    case 'GET_CLIENT_BUILDING_DOCUMENT':
      return { ...state, selectedClientBuildingDocument: action.selectedClientBuildingDocument }
    case 'GET_CLIENT_BUILDING_IMAGE':
      return { ...state, selectedClientBuildingImage: action.selectedClientBuildingImage }
    case "CLEAR_ALL":
      return initialState
      
    case 'CLIENTBUILDINGERROR':
      return { ...state, clientBuildingError: action.data }
    default:
      return { ...state }
  }
}
export default ClientBuildingReducer