// ** Initial State
const initialState = {
  allData: [],
  allSCData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetSubCategory: null
}

const ListAssetSubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_SUB_CATEGORY':
      return { ...state, allSCData: action.data, allData: action.data }
    case 'GET_ALL_IN_ASSET_SUB_CATEGORY':
      return { ...state, allSCData: action.data, allData: action.data }
    case 'GET_DATA_ASSET_SUB_CATEGORY':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_SUB_CATEGORY':
      return { ...state, selectedAssetSubCategory: action.selectedAssetSubCategory }
    case 'ASSETSUBCATEGORYERROR':
      return { ...state, assetSubCategoryError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetSubCategoryReducer