// ** Initial State
const initialState = {
    allAutoAssignData: [],
    allAutoAssignScheduleData: [],
    allNewSTReminderData: [],
    allNewReminderHoursData: [],
    allNegativeRatingData: [],
    allDowntimeData: [],
    allPromisedDateData: [],
    
    allEmailReminderData: [],
    allEmailReminderScheduleData: [],
    
    allReminderOptionData: [],
    allAutoCloseData: null,
    data: [],
    total: 1,
    params: {},
    selectedServiceType: null
  }
  
  const ListServiceTicketReminderReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_SERVICE_TICKET_AUTO_ASSIGN':
        return { ...state, allAutoAssignData: action.data }
      case 'GET_ALL_SERVICE_TICKET_REMINDER_OPTION':
        return { ...state, allReminderOptionData: action.data }
      case 'GET_ALL_SERVICE_TICKET_AUTO_CLOSE':
        return { ...state, allAutoCloseData: action.data }
      case 'GET_ALL_SERVICE_TICKET_AUTO_ASSIGN_SCHEDULE':
        return { ...state, allAutoAssignScheduleData: action.data }
      case 'GET_ALL_NEW_SERVICE_TICKET_REMINDER':
        return { ...state, allNewSTReminderData: action.data }
      case 'GET_ALL_SERVICE_TICKET_NEW_REMINDER_HOURS':
        return { ...state, allNewReminderHoursData: action.data }
      case 'GET_ALL_SERVICE_TICKET_NEGATIVE_RATING':
        return { ...state, allNegativeRatingData: action.data }
      case 'GET_ALL_SERVICE_TICKET_DOWNTIME':
        return { ...state, allDowntimeData: action.data }
      case 'GET_ALL_SERVICE_TICKET_PROMISED_DATE':
        return { ...state, allPromisedDateData: action.data }
      case 'GET_ALL_EMAIL_REMINDER':
        return { ...state, allEmailReminderData: action.data }
      case 'GET_ALL_EMAIL_REMINDER_SCHEDULE':
        return { ...state, allEmailReminderScheduleData: action.data }
      
      case 'SERVICETICKETREMINDERERROR':
        return { ...state, serviceTicketReminderError: action.data }
      default:
        return { ...state }
    }
  }
  export default ListServiceTicketReminderReducer