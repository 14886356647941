// ** Initial State
const initialState = {
  allData: [],
  allCountryData: [],
  data: [],
  dataCountry: [],
  total: 1,
  params: {},
  selectedAssetState: null
}

const ListAssetStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_STATE':
      return { ...state, allData: action.data }
    case 'GET_ALL_ASSET_COUNTRY':
      return { ...state, allCountryData: action.data }
    case 'GET_DATA_ASSET_STATE':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ASSET_COUNTRY':
        return {
          ...state,
          dataCountry: action.data,
          total: action.totalPages,
          params: action.params
        }
    case 'GET_ASSET_STATE':
      return { ...state, selectedAssetState: action.selectedAssetState }
    case 'ASSETSTATEERROR':
      return { ...state, assetStateError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetStateReducer