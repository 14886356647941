// **  Initial State
const initialState = {
  userData: {},
  userProfile: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.data }
    case 'LOGINERROR':
      return { ...state, loginError: action.data }
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'PROFILEERROR':
      return { ...state, profileError: action.data }
    case 'CAPTCHA':
      return { ...state, userData: action.data }
    case 'CAPTCHAERROR':
      return { ...state, captchaError: action.data }
    case 'FORGOTPASSWORD':
      return { ...state, userData: action.data }
    case 'LOGOUT':
      return { ...state, userData: {} }
    default:
      return state
  }
}

export default authReducer
