// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetMakeModelSegment: null
}

const ListAssetMakeModelSegmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_MAKE_MODEL_SEGMENT':
      return { ...state, allData: action.data }
    case 'GET_DATA_ASSET_MAKE_MODEL_SEGMENT':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_MAKE_MODEL_SEGMENT':
      return { ...state, selectedAssetMakeModelSegment: action.selectedAssetMakeModelSegment }
    case 'ASSETMAKEMODELSEGMENTERROR':
      return { ...state, assetMakeModelSegmentError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetMakeModelSegmentReducer