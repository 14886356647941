// ** Initial State
const initialState = {
  allData: [],
  allLocData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetLocation: null,
  selectedAssetLocationImage: null,
  selectedAssetLocationMap: null
}

const ListAssetLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_LOCATION':
      return { ...state, allLocData: action.data, allData: action.data }
    case 'GET_DATA_ASSET_LOCATION':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_LOCATION':
      return { ...state, selectedAssetLocation: action.selectedAssetLocation }
      case 'GET_ASSET_LOCATION_IMAGE':
        return { ...state, selectedAssetLocationImage: action.selectedAssetLocationImage }
        case 'GET_ASSET_LOCATION_MAP':
          return { ...state, selectedAssetLocationMap: action.selectedAssetLocationMap }
        case 'ASSETLOCATIONERROR':
      return { ...state, assetLocationError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetLocationReducer