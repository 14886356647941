// ** Initial State
const initialState = {
  allData: [],
  data: [],
  allRoomData: [],
  total: 1,
  params: {},
  selectedClientFloor: null
}

const ClientFloorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CLIENT_FLOOR':
      return { ...state, allData: action.data }
      case 'GET_ALL_IN_ROOM_CLIENT_FLOOR':
        return { ...state, allRoomData: action.data }
        case 'GET_ALL_IN_ASSET_CLIENT_FLOOR':
          return { ...state, allData: action.data }
            
    case 'GET_DATA_CLIENT_FLOOR':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLIENT_FLOOR':
      return { ...state, selectedClientFloor: action.selectedClientFloor }
    case 'GET_CLIENT_FLOOR_DOCUMENT':
      return { ...state, selectedClientFloorDocument: action.selectedClientFloorDocument }
    case 'GET_CLIENT_FLOOR_IMAGE':
      return { ...state, selectedClientFloorImage: action.selectedClientFloorImage }
    case "CLEAR_ALL":
      return initialState
        
    case 'CLIENTFLOORERROR':
      return { ...state, clientFloorError: action.data }
    default:
      return { ...state }
  }
}
export default ClientFloorReducer