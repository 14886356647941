// ** Initial State
const initialState = {
  allData: [],
  allAssetData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetModel: null,
  selectedAssetModelImage: null,
  selectedAssetModelDocument: null
}

const ListAssetModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_MODEL':
      return { ...state, allData: action.data }
    case 'GET_ALL_ASSET_MODEL_IN_ASSET':
      return { ...state, allAssetData: action.data }
    
    case 'GET_DATA_ASSET_MODEL':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_MODEL':
      return { ...state, selectedAssetModel: action.selectedAssetModel }
    case 'GET_ASSET_MODEL_DOCUMENT':
      return { ...state, selectedAssetModelDocument: action.selectedAssetModelDocument }
      
    case 'ASSETMODELERROR':
      return { ...state, assetModelError: action.data }
    case 'CLEAR_ALL':
      return initialState
    default:
      return { ...state }
  }
}
export default ListAssetModelReducer