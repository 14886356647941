// ** Initial State
const initialState = {
  clientData: [],
  serviceTicketData: [],
  total: 1,
  params: {},
  selectedServiceTicketAsset: null,
  selectedServiceTicketFeedback: null
}

const PublicLeafReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SERVICE_TICKET_FEEDBACK_CLIENT':
      return { ...state, clientData: action.data }
    case 'GET_SERVICE_TICKET_FEEDBACK_SERVICE_TICKET':
      return { ...state, serviceTicketData: action.data }
    case 'GET_SERVICE_TICKET_ASSET_DETAIL':
      return { ...state, selectedServiceTicketAsset: action.data }
    case 'SERVICETICKETERROR':
      return { ...state, serviceTIcketFeedbackError: action.data }
    default:
      return { ...state }
  }
}
export default PublicLeafReducer