// ** Initial State
const initialState = {
  allData: [],
  allSTTypeData: [],
  data: [],
  total: 1,
  params: {},
  selectedServiceTicketPriority: null
}

const ListServiceTicketPriorityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_SERVICE_TICKET_PRIORITY':
      return { ...state, allData: action.data }
    case 'GET_ALL_SERVICE_TICKET_PRIORITY_TYPE':
      return { ...state, allSTTypeData: action.data }
    case 'GET_DATA_SERVICE_TICKET_PRIORITY':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_SERVICE_TICKET_PRIORITY':
      return { ...state, selectedServiceTicketPriority: action.selectedServiceTicketPriority }
    case 'SERVICETICKETPRIORITYERROR':
      return { ...state, serviceTicketPriorityError: action.data }
    default:
      return { ...state }
  }
}
export default ListServiceTicketPriorityReducer