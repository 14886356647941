// ** Initial State
const initialState = {
  allData: [],
  allRoomData: [],
  data: [],
  total: 1,
  params: {},
  selectedClientBranch: null,
  selectedClientBranchLocation: null
}

const ClientBranchReducer = (state = initialState, action) => {
  switch (action.type) {  
    case 'GET_ALL_CLIENT_BRANCH':
      return { ...state, allData: action.data }
    case 'GET_ALL_IN_ASSET_CLIENT_BRANCH':
      return { ...state, allData: action.data }
    case 'GET_ALL_IN_ROOM_CLIENT_BRANCH':
      return { ...state, allRoomData: action.data }
    case 'GET_DATA_CLIENT_BRANCH':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLIENT_BRANCH':
      return { ...state, selectedClientBranch: action.selectedClientBranch }
    case 'GET_CLIENT_BRANCH_LOCATION':
      return { ...state, selectedClientBranchLocation: action.selectedClientBranch }
    case 'GET_CLIENT_BRANCH_DOCUMENT':
      return { ...state, selectedClientBranchDocument: action.selectedClientBranchDocument }
    case 'GET_CLIENT_BRANCH_IMAGE':
      return { ...state, selectedClientBranchImage: action.selectedClientBranchImage }
    case "CLEAR_ALL":
      return initialState
    case 'CLIENTBRANCHERROR':
      return { ...state, clientBranchError: action.data }
    default:
      return { ...state }
  }
}
export default ClientBranchReducer