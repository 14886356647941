// ** Initial State
const initialState = {
    allData: [],
    allFFFData: [],
    data: [],
    total: 1,
    params: {},
    selectedRoomFittingFurnitureFamily: null
  }
  
  const ListRoomFittingFurnitureFamilyReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_ROOM_FITTING_FURNITURE_FAMILY':
        return { ...state, allFFFData: action.data, allData: action.data }
      case 'GET_ALL_IN_ROOM_FITTING_FURNITURE_FAMILY':
        return { ...state, allFFFData: action.data, allData: action.data }
      case 'GET_DATA_ROOM_FITTING_FURNITURE_FAMILY':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_ROOM_FITTING_FURNITURE_FAMILY':
        return { ...state, selectedRoomFittingFurnitureFamily: action.selectedRoomFittingFurnitureFamily }
      case 'ROOMFITTINGFURNITUREFAMILYERROR':
        return { ...state, roomFittingFurnitureFamilyError: action.data }
      default:
        return { ...state }
    }  
  }
  export default ListRoomFittingFurnitureFamilyReducer