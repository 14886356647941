import mock from '../../mock'
import { paginateArray } from '../../utils'
 
const data = {
  labels: [
    { item: "Unique Asset", order: "1", content: require('@src/assets/images/avatars/10.png').default},
    { item: "Profile Picture", order: "2", content: "<automatic>"},
    { item: "Details", order: "3", content: "EFG Hospital"},
    { item: "Vendor", order: "4", content: "<automatic>"},
    { item: "AMC/CMC", order: "5", content: "abc"}
  ]
  // pmlabels: [
  //   { item: "ClientLogo", order: "1", content: require('@src/assets/images/avatars/11.png').default},
  //   { item: "Asset QR", order: "2", content: "<automatic>"},
  //   { item: "Choose PM Fields", order: "3", content: "EFG Hospital"},
  //   { item: "Asset Serial Number", order: "4", content: "<automatic>"},
  //   { item: "Client Note", order: "5", content: "xyz"},
  //   { item: "LSM Note", order: "6", content: "wuv"}
  // ]
}
// GET ALL DATA

mock.onGet('/api/masters/user/userlabel/all-data').reply(() => {
  return [200, data.labels]
})
mock.onGet('/api/masters/user/userlabel/all-pm-data').reply(() => {
  return [200, data.pmlabels]
})

// GET USER
mock.onGet('/api/masters/user/userlabel').reply(config => {
  const { item } = config
  const label = data.labels.find(i => i.item === item)
  return [200, { label }]
})
