// ** Initial State
const initialState = {
  allData: [],
  allLocationData: [],
  data: [],
  clientData: [],
  generalData: [],
  calibrationData: [],
  docData: [],
  serviceTicketData: [],
  pmData: [],
  qaData: [],
  generalImageData: [],
  total: 1,
  params: {},
  selectedPublicLeaf: null
}

const PublicLeafReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PUBLIC_LEAF':
      return { ...state, allData: action.data }
    case 'GET_PUBLIC_LEAF_CLIENT':
      return { ...state, clientData: action.data }
    case 'GET_PUBLIC_LEAF_GENERAL':
      return { ...state, generalData: action.data }
    case 'GET_PUBLIC_LEAF_GENERAL_IMAGE':
      return { ...state, generalImageData: action.data }
    case 'GET_PUBLIC_LEAF_CALIBRATION':
      return { ...state, calibrationData: action.data }
    case 'GET_PUBLIC_LEAF_SERVICE_TICKET':
      return { ...state, serviceTicketData: action.data }
    case 'GET_PUBLIC_LEAF_PM':
      return { ...state, pmData: action.data }
    case 'GET_PUBLIC_LEAF_DOCUMENT':
      return { ...state, docData: action.data }
    case 'GET_PUBLIC_LEAF_ASSET_LOCATION':
      return { ...state, allLocationData: action.data }
    case 'GET_PUBLIC_LEAF_QA':
      return { ...state, qaData: action.data }
    case 'GET_DATA_PUBLIC_LEAF':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PUBLIC_LEAF':
      return { ...state, selectedPublicLeaf: action.selectedPublicLeaf }
    case 'PUBLICLEAFERROR':
      return { ...state, publicLeafError: action.data }
    default:
      return { ...state }
  }
}
export default PublicLeafReducer