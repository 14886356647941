// ** Initial State
const initialState = {
  selectedServiceTicket: null,
  serviceOpenTicketID: false,
  serviceCloseTicketID: false,
  allSTData: [],
  allServiceTicketData: [],
  allServiceTicketFeedbackData: [],
  allServiceTicketDataByAsset: [],
  allServiceTicketDataByAssetHistory: [],
  allSTStatusCountData: [],
  allSTOpenStatusCountData: [],
  allSTDowntimeCountData: [],
  allSTCostCountData: [],
  allSTStatusData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetOpenedServiceTicket: [],
  selectedServiceTicketDocument: [],
  selectedAssetClosedServiceTicket: [],
  selectedServiceTicketFeedback: [],
  selectedServiceTicketReOpenHistory: null,
  selectedServiceTicketComponent: [],
  selectedServiceTicketTotalCost: [],
  selectedServiceTicketLabour: [],
  selectedServiceTicketUnknown: [],
  selectedAssetStatus: [],
  selectedServiceTicketService: [],
  selectedServiceTicketUpdatesLevel1Escalation: null,
  selectedServiceTicketUpdatesLevel2Escalation: null,
  selectedServiceTicketUpdatesLevel3Escalation: null,
  selectedServiceTicketUpdatesComplainant: null,
  selectedServiceTicketUpdatesPrimaryAssigned: null,
  selectedServiceTicketUpdatesSecondaryAssigned: null,
  selectedServiceTicketUpdatesPrimaryTechnician: null,
  selectedServiceTicketUpdatesUserSTMenu: null
  

}

const ServiceTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SERVICE_TICKET':
      return { ...state, selectedServiceTicket: action.selectedServiceTicket }
    case 'GET_ALL_SERVICE_TICKET':
      return { ...state, allSTData: action.allSTData }
      case 'GET_SERVICE_TICKET_DOCUMENT':
        return { ...state, selectedServiceTicketDocument: action.selectedServiceTicketDocument }
    case 'GET_SERVICE_TICKET_DATA_BY_ASSET':
      return { ...state, allServiceTicketDataByAsset: action.allServiceTicketData }
      case 'GET_SERVICE_TICKET_DATA_BY_ASSET_HISTORY':
        return { ...state, allServiceTicketDataByAssetHistory: action.allServiceTicketHistoryData }
      
    case 'GET_SERVICE_TICKET_DATA':
      return { ...state, allServiceTicketData: action.allServiceTicketData }
      case 'GET_SERVICE_TICKET_FEEDBACK_DATA':
        return { ...state, allServiceTicketFeedbackData: action.allServiceTicketFeedbackData }
      case 'GET_ALL_SERVICE_TICKET_STATUS_COUNT':
      return { ...state, allSTStatusCountData: action.allSTStatusCountData }
      case 'GET_ALL_SERVICE_TICKET_OPEN_STATUS_COUNT':
        return { ...state, allSTOpenStatusCountData: action.allSTOpenStatusCountData }
      case 'GET_ALL_SERVICE_TICKET_STATUS':
      return { ...state, allSTStatusData: action.allSTStatusData }
    case 'GET_ALL_SERVICE_TICKET_DOWNTIME_COUNT':
      return { ...state, allSTDowntimeCountData: action.allSTDowntimeCountData }
    case 'GET_ALL_SERVICE_TICKET_COST_COUNT':
      return { ...state, allSTCostCountData: action.allSTCostCountData }
    case 'GET_SERVICE_TICKET_FEEDBACK':
      return { ...state, selectedServiceTicketFeedback: action.data }
      case 'GET_SERVICE_TICKET_REOPEN_HISTORY':
        return { ...state, selectedServiceTicketReOpenHistory: action.data }
      case 'GET_SERVICE_TICKET_COMPONENT':
      return { ...state, selectedServiceTicketComponent: action.data }
      case 'GET_SERVICE_TICKET_TOTAL_COST':
        return { ...state, selectedServiceTicketTotalCost: action.data }
      case 'GET_SERVICE_TICKET_LABOUR':
      return { ...state, selectedServiceTicketLabour: action.data }
    case 'GET_ASSET_OPENED_SERVICE_TICKET':
      return { ...state, selectedAssetOpenedServiceTicket: action.selectedAssetOpenedServiceTicket }
    case 'GET_ASSET_CLOSED_SERVICE_TICKET':
      return { ...state, selectedAssetClosedServiceTicket: action.selectedAssetClosedServiceTicket }
    case 'GET_ASSET_STATUS':
      return { ...state, selectedAssetStatus: action.selectedAssetStatus }
    case 'GET_SERVICE_TICKET_UNKNOWN':
      return { ...state, selectedServiceTicketUnknown: action.selectedServiceTicketUnknown }
    case 'GET_SERVICE_TICKET_SERVICE':
      return { ...state, selectedServiceTicketService: action.selectedServiceTicketService }
    

    case 'GET_SERVICE_TICKET_UPD_LEVEL1ESCALATION':
      return { ...state, selectedServiceTicketUpdatesLevel1Escalation: action.data }
    case 'GET_SERVICE_TICKET_UPD_LEVEL2ESCALATION':
      return { ...state, selectedServiceTicketUpdatesLevel2Escalation: action.data }
    case 'GET_SERVICE_TICKET_UPD_LEVEL3ESCALATION':
      return { ...state, selectedServiceTicketUpdatesLevel3Escalation: action.data }
    case 'GET_SERVICE_TICKET_UPD_COMPLAINANT':
      return { ...state, selectedServiceTicketUpdatesComplainant: action.data }
    case 'GET_SERVICE_TICKET_UPD_PRIMARYASSIGNED':
      return { ...state, selectedServiceTicketUpdatesPrimaryAssigned: action.data }
    case 'GET_SERVICE_TICKET_UPD_SECONDARYASSIGNED':
      return { ...state, selectedServiceTicketUpdatesSecondaryAssigned: action.data }
    case 'GET_SERVICE_TICKET_UPD_USERSTMENU':
      return { ...state, selectedServiceTicketUpdatesUserSTMenu: action.data }
    case 'GET_SERVICE_TICKET_UPD_PRIMARYTECHNICIAN':
      return { ...state, selectedServiceTicketUpdatesPrimaryTechnician: action.data }
  
        
    case 'SERVICETICKETERROR':
      return { ...state, serviceTicketError: action.data }
    case "CLEAR_ALL":
      return initialState
    default:
      return { ...state }
  }
}
export default ServiceTicketReducer