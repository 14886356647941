// ** Initial State
const initialState = {
  allData: [],
  allAssetData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetFamily: null,
  selectedAssetFamilyDocument: null
}

const ListAssetFamilyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_FAMILY':
      return { ...state, allData: action.data }
    case 'GET_ALL_ASSET_FAMILY_IN_ASSET':
      return { ...state, allAssetData: action.data }
    case 'GET_DATA_ASSET_FAMILY':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_FAMILY':
      return { ...state, selectedAssetFamily: action.selectedAssetFamily }
    case 'GET_ASSET_FAMILY_DOCUMENT':
      return { ...state, selectedAssetFamilyDocument: action.selectedAssetFamilyDocument }
      
    case 'ASSETFAMILYERROR':
      return { ...state, assetFamilyError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetFamilyReducer