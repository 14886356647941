// ** Initial State
const initialState = {
  allData: [],
  allAssetData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetManufacturer: null
}

const ListAssetManufacturerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_MANUFACTURER':
      return { ...state, allData: action.data }
    case 'GET_ALL_ASSET_MANUFACTURER_IN_ASSET':
      return { ...state, allAssetData: action.data }
    
    case 'GET_DATA_ASSET_MANUFACTURER':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_MANUFACTURER':
      return { ...state, selectedAssetManufacturer: action.selectedAssetManufacturer }
    case 'ASSETMANUFACTURERERROR':
      return { ...state, assetManufacturerError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetManufacturerReducer