// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedAssetContact: null
}

const ListAssetContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ASSET_CONTACT':
      return { ...state, allData: action.data }
    case 'GET_DATA_ASSET_CONTACT':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ASSET_CONTACT':
      return { ...state, selectedAssetContact: action.selectedAssetContact }
    case 'ASSETCONTACTERROR':
      return { ...state, assetContactError: action.data }
    default:
      return { ...state }
  }
}
export default ListAssetContactReducer