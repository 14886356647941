import MockAdapter from 'axios-mock-adapter'
import axios from 'axios'

// Global variable for post request with axios
const global_post_url = axios.create({
  baseURL: "https://littlestellamaris.com/",
  headers: {'Content-Type': 'application/json'}
})

// const mock = new MockAdapter(global_post_url)
const mock = new MockAdapter(axios)

export default mock
   