// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedRoomType: null
  }
  
  const ListRoomTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_ROOM_TYPE':
        return { ...state, allData: action.data }
      case 'GET_DATA_ROOM_TYPE':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_ROOM_TYPE':
        return { ...state, selectedRoomType: action.selectedRoomType }
      case 'ROOMTYPEERROR':
        return { ...state, assetTypeError: action.data }
      default:
        return { ...state }
    }
  }
  export default ListRoomTypeReducer