// **  Initial State
const initialState = {
  statesData: [],
  statusData: [],
  statusWithRepairData: [],
  currenciesData: [],
  total: 1,
  params: {},
  selectedCountryState: null,
  selectedStatus: null
}

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALLSTATES':
      return { ...state, statesData: action.data }
    case 'ALLCURRENCIES':
      return { ...state, currenciesData: action.data }
    case 'ALLSTATUS':
      return { ...state, statusData: action.data }
    case 'ALLSTATUSWITHREPAIR':
      return { ...state, statusWithRepairData: action.data }
    case 'STATUS':
      return { ...state, selectedStatus: action.data }
    default:
      return state
  }
}

export default siteReducer
