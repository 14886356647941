import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  clients: [
    {
      id: 1,
      client_unique_id: 'CL-12345678',
      client_name: 'Sarkar Hospital',
      client_email: 'abc@gmail.com',
      client_phone: '9535577458',
      client_primary_contact: 'Soma',
      client_contact_phone: '9534658921',
      no_of_users: '3'
    },
    {
      id: 2,
      client_unique_id: 'CL-12565678',
      client_name: 'HCG Hospital',
      client_email: 'hcg@gmail.com',
      client_phone: '9123477458',
      client_primary_contact: 'ABC',
      client_contact_phone: '9534158921',
      no_of_users: '5'
    },
    {
      id: 3,
      client_unique_id: 'CL-78945678',
      client_name: 'Manipal Hospital',
      client_email: 'mani@gmail.com',
      client_phone: '9905577458',
      client_primary_contact: 'Mani',
      client_contact_phone: '9539878921',
      no_of_users: '8'
    }
    
  ]
}

// GET ALL DATA
mock.onGet('/api/clients/list/all-data').reply(200, data.clients)

// GET Updated DATA
mock.onGet('/api/clients/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, currentPlan = null, status = null } = config
  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.clients.filter(
    client =>
      (client.client_unique_id.toLowerCase().includes(queryLowered) || client.client_email.toLowerCase().includes(queryLowered)) &&
      client.role === (role || client.role) &&
      client.currentPlan === (currentPlan || client.currentPlan) &&
      client.status === (status || client.status)
  )
  /* eslint-enable  */
  
  return [
    200,
    {
      clients: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET client
mock.onGet('/api/clients/user').reply(config => {
  const { id } = config
  const client = data.clients.find(i => i.id === id)
  return [200, { client }]
})
