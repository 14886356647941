// ** Initial State
const initialState = {
  allData: [],
  allMCData: [],
  data: [],
  total: 1,
  params: {},
  selectedRoomMainCategory: null
}

const ListRoomMainCategoryReducer = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case 'GET_ALL_ROOM_MAIN_CATEGORY':
      return { ...state, allMCData: action.data, allData: action.data }
    case 'GET_ALL_IN_ROOM_MAIN_CATEGORY':
      return { ...state, allMCData: action.data, allData: action.data }
    case 'GET_DATA_ROOM_MAIN_CATEGORY':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ROOM_MAIN_CATEGORY':
      return { ...state, selectedRoomMainCategory: action.selectedRoomMainCategory }
    case 'ROOMMAINCATEGORYERROR':
      return { ...state, roomMainCategoryError: action.data }
    default:
      return { ...state }
  }
}
export default ListRoomMainCategoryReducer