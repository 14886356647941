// ** Initial State
const initialState = {
    allData: [],
    specValueData: [],
    allTypeData: [],
    data: [],
    total: 1,
    params: {},
    selectedAssetSpecification: null,
    allDPValueData: [[{value: null, label: "All Specification Values"}]]
  }
  
  const ListAssetSpecificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_ASSET_SPECIFICATION':
        return { ...state, allData: action.data }
        case 'GET_SPECIFIC_SPECIFICATION_VALUE':
          return { ...state, specValueData: action.data }
      case 'GET_ALL_ASSET_SPECIFICATION_TYPE':
        return { ...state, allTypeData: action.data }
        case 'ALL_SPECIFICATION_DROPDOWN_VALUE':
          return { ...state, allDPValueData: action.data }
          
      case 'GET_DATA_ASSET_SPECS':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_ASSET_SPECIFICATION':
        return { ...state, selectedAssetSpecification: action.selectedAssetSpecification }
      case 'ASSETSPECIFICATIONERROR':
        return { ...state, assetSpecificationError: action.data }
      default:
        return { ...state }
    }
  }
  export default ListAssetSpecificationReducer