// ** Initial State
const initialState = {
  allQRData: [],
  allData:[],
  allEditData: [],
  userSTData:[],
  allUserSTData:[],
  allUserTechnicianData:[],
  data: [],
  total: 1,
  params: {}
}

const UserMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_USER_PERMISSION_DATA':
      return { ...state, allQRData: action.data }
    case 'GET_ALL_USER':
      return { ...state, allData: action.data }
    case 'GET_ALL_USER_EDIT':
      return { ...state, allEditData: action.data }
    case 'GET_USER_ST':
      return { ...state, userSTData: action.data }
    case 'GET_ALL_USER_ST':
      return { ...state, allUserSTData: action.data }
    case 'GET_ALL_USER_TECHNICIAN':
      return { ...state, allUserTechnicianData: action.data }
    case 'USERERROR':
      return { ...state, userError: action.data }
    case "CLEAR_ALL":
      return initialState
    default:
      return { ...state }
  }
}
export default UserMasterReducer
