// ** Initial State
const initialState = {
  allData: [],
  allPMData: [],
  allQRData: [],
  allQData: [],
  allCalibrationData: [],
  data: [],
  total: 1,
  params: {}
}

const LabelMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QR_LABEL_DATA':
      return { ...state, allQData: action.data }
      case 'GET_ALL_LABEL_DATA':
        return { ...state, allData: action.data }
      case 'GET_ALL_PM_LABEL_DATA':
      return { ...state, allPMData: action.data }
    case 'GET_ALL_CALIBRATION_LABEL_DATA':
      return { ...state, allCalibrationData: action.data }
    case 'GET_ALL_QR_LABEL_DATA':
      return { ...state, allQRData: action.data }
    default:
      return { ...state }
  }
}
export default LabelMasterReducer
